export default [
    // 메뉴, 권한
    { path: '/system/menu', name: 'system-menu', component: () => import('../views/system/Menu.vue') },
    { path: '/system/role', name: 'system-role', component: () => import('../views/system/Role.vue') },
    { path: '/system/role/menu', name: 'system-role-menu', component: () => import('../views/system/RoleMenu.vue') },
    { path: '/system/viewHistory', name: 'system-view-history', component: () => import('../views/system/ViewHistory.vue') },
    { path: '/system/admin', name: 'system-admin', component: () => import('../views/system/AdminList.vue') },
    { path: '/system/admin/insert', name: 'system-admin-insert', component: () => import('../views/system/Admin.vue') },
    { path: '/system/admin/:id', name: 'system-admin-view', component: () => import('../views/system/AdminView.vue'), props: true },

    // 정책
    { path: '/system/policy', name: 'system-policy', component: () => import('../views/system/policy/Policy.vue') },
    { path: '/system/policyHistory', name: 'system-policy-history', component: () => import('../views/system/policy/PolicyHistory.vue') },

    // 약관
    {
        path: '/system/terms/:termsType', name: 'system-terms', component: () => import('../views/system/terms/Terms.vue'),
        children: [
            { path: '', name: 'terms-list', component: () => import('../views/system/terms/TermsList') },
            { path: 'form', name: 'terms-form', component: () => import('../views/system/terms/TermsForm.vue') },
            { path: 'update/:id', name: 'terms-update', component: () => import('../views/system/terms/TermsForm.vue') },
        ]
    },

    // 코드관리
    { path: '/system/code/:groupType', name: 'system-code', component: () => import('../views/system/Code.vue') },

    // 알림관리
    { path: '/system/message/config', name: 'system-message-config', component: () => import('../views/system/message/MessageConfig.vue') },
    { path: '/system/message/history', name: 'system-message-history', component: () => import('../views/system/message/MessageHistory.vue') },
    { path: '/system/message/reserveHistory', name: 'system-message-reserveHistory', component: () => import('../views/system/message/MessageReserveHistory.vue') },
    { path: '/system/message/send', name: 'system-message-send', component: () => import('../views/system/message/MessageSend.vue') },
    { path: '/system/message/store', name: 'system-message-store', component: () => import('../views/system/message/MessageStore.vue') },

    // 상담관리
    { path: '/system/counsel/config', name: 'system-counsel-config', component: () => import('../views/system/counsel/CounselConfig.vue') },
    { path: '/system/counsel/hashtag', name: 'system-counsel-hashtag', component: () => import('../views/system/counsel/CounselHashtag.vue') },
    { path: '/system/counsel/ingredient', name: 'system-counsel-ingredient', component: () => import('../views/system/counsel/CounselIngredient.vue') },
    { path: '/system/counsel/chart', name: 'system-counsel-chart', component: () => import('../views/system/counsel/chart/ChartConfig.vue') },
]