<template>
    <component :is="state.layout" >
        <slot  />
    </component>
</template>

<script>

import { reactive, computed } from 'vue';
import { useRoute } from 'vue-router'
import LayoutDefault from './LayoutDefault';
import LayoutNone from './LayoutNone';

export default {
    components: {
        LayoutDefault,
        LayoutNone,
    },

    setup() {

        const route = useRoute();

        const state = reactive({
            layout: computed(() => {
                //   return Number(this.$store.state.userInfo.expire) > Number(this.$comUtil.getDate('yyyyMMddHHmmss', new Date)) 
                //       && this.$route.meta.authRequired != false
                //       ? 'LayoutDefault' : 'LayoutNone'
                  return route.meta.authRequired != false ? 'LayoutDefault' : 'LayoutNone'

            }),
        });

        return {
            state
        }
    },
}




</script>