export default [
  {path: '/goodsOrder', name: 'goodsOrder', component: () => import('../views/goodsOrder/GoodsOrder.vue'),
      children: [
          {path: '',name: 'goodsOrder-list', component: () => import('../views/goodsOrder/GoodsOrderList.vue')},
          {path: '/goodsOrder/:id',name: 'goodsOrder-view', component: () => import('../views/goodsOrder/GoodsOrderForm.vue')},
         
      ]
  },
  {path: '/goodsOrder/deliveryManage',name: 'goodsOrder-deliveryManage', component: () => import('../views/goodsOrder/DeliveryManage.vue')},
  {path: '/goodsOrder/claimManage',name: 'goodsOrder-claimManage', component: () => import('../views/goodsOrder/ClaimManage.vue')},
]