import axios from "axios";
import { ElMessage } from "element-plus";

const invoiceBaseUrl = "http://175.106.98.18:8888";
export default {
    // 택배사 전체 조회
    async onGetParcelList() {
        return new Promise((resolve) => {
            resolve(
                axios
                    .get(`${invoiceBaseUrl}/carriers`)
                    .then(async (res) => {
                        return await res.data;
                    })
                    .catch((ex) => {
                        console.log("택배사 조회 에러", ex);
                    })
            );
        });
    },
    // 택배사 단일 조회
    // parcelId: 택배사 아이디
    async onGetParcel(parcelId) {
        return new Promise((resolve) => {
            resolve(
                axios
                    .get(`${invoiceBaseUrl}/carriers/${parcelId}`)
                    .then(async (res) => {
                        return await res.data;
                    })
                    .catch((ex) => {
                        console.log("택배사 단일 조회 에러", ex);
                    })
            );
        });
    },
    // 택배조회
    // parcelId: 택배사 아이디
    // trackId: 송장번호
    async onGetTrackingInfo(parcelId, trackId) {
        return new Promise((resolve) => {
            resolve(
                axios
                    .get(
                        `${invoiceBaseUrl}/carriers/${parcelId}/tracks/${trackId}`
                    )
                    .then(async (res) => {
                        return await res.data;
                    })
                    .catch((ex) => {
                        console.log("택배조회 에러", ex);
                    })
            );
        });
    },
    isValidBusinessNumber(businessNumber, muteYN) {
        //사업자 번호 유효성 체크
        muteYN = muteYN || false;
        const params = {
            businessNumber: businessNumber,
        };
        let isValid = false;
        axios
            .get("/api/open-api/business-number/" + businessNumber)
            .then((res) => {
                if (res.data.taxStatus) {
                    if (!muteYN) {
                        ElMessage({
                            type: "success",
                            message: "유효한 사업자번호입니다",
                        });
                    }
                    isValid = true;
                } else {
                    if (!muteYN) {
                        ElMessage({
                            type: "warning",
                            message: res.data.taxType,
                        });
                    }
                }
            })
            .catch((err) => {
                ElMessage({ type: "error", message: "확인에 실패하였습니다" });
                console.log(err);
            });
        return isValid;
    },

    async getAddresSido() {
        //광역시도
        let list = [];
        await axios.get("/api/open-api/address/sido").then((res) => {
            list = res.data.admVOList.admVOList;
        });
        return list;
    },

    async getAddresSigungu(sdCode) {
        //광역시도 소속 시군구
        let list = [];
        await axios
            .get("/api/open-api/address/sigungu/" + sdCode)
            .then((res) => {
                list = res.data.admVOList.admVOList;
            });
        return list;
    },

    async getAddresEupmyundong(sggCode) {
        //시군구 소속 읍면동
        let list = [];
        await axios
            .get("/api/open-api/address/eupmyundong/" + sggCode)
            .then((res) => {
                list = res.data.admVOList.admVOList;
            });
        return list;
    },

    searchPostalCode(target) {
        new window.daum.Postcode({
            oncomplete: (data) => {
                // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

                // 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
                // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
                let fullRoadAddr = data.roadAddress; // 도로명 주소 변수
                let extraRoadAddr = ""; // 도로명 조합형 주소 변수

                // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
                    extraRoadAddr += data.bname;
                }
                // 건물명이 있고, 공동주택일 경우 추가한다.
                if (data.buildingName !== "" && data.apartment === "Y") {
                    extraRoadAddr +=
                        extraRoadAddr !== ""
                            ? ", " + data.buildingName
                            : data.buildingName;
                }
                // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
                if (extraRoadAddr !== "") {
                    extraRoadAddr = " (" + extraRoadAddr + ")";
                }
                // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
                if (fullRoadAddr !== "") {
                    fullRoadAddr += extraRoadAddr;
                }

                if (target.postalCode) {
                    target.dataObj[target.postalCode] = data.zonecode;
                }
                if (target.addressDoro) {
                    target.dataObj[target.addressDoro] = data.roadAddress;
                }
                if (target.addressJibun) {
                    target.dataObj[target.addressJibun] = data.bname;
                }
                if (target.addressCode) {
                    target.dataObj[target.addressCode] = data.bcode;
                }
                if (target.callback) {
                    target.callback();
                }
            },
        }).open();
    },
};
