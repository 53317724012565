export default [
    // {path: '/board/notice', name: 'board-notice', component: () => import('../views/board/Board.vue'),
    //     children: [
    //         {path: '',name: 'board-list', component: () => import('../views/board/BoardList.vue')},
    //         {path: 'form',name: 'board-form', component: () => import('../views/board/BoardForm.vue')},
    //         {path: 'view/:id',name: 'board-view', component: () => import('../views/board/BoardForm.vue')},
    //     ]
    // },
    {path: '/board/FAQ',name: 'board-faq', component: () => import('../views/board/Board.vue'),
        children: [
            {path: '',name: 'board-faq', component: () => import('../views/board/BoardFAQ.vue')},
            {path: 'form',name: 'board-faq-form', component: () => import('../views/board/BoardFAQForm.vue')},
            {path: 'view/:id',name: 'board-faq-view', component: () => import('../views/board/BoardFAQForm.vue')},
        ]
    },
    {path: '/board/directQuestion',name: 'board-directquestion', component: () => import('../views/board/Board.vue'),
        children: [
            {path: '',name: 'board-directquestion', component: () => import('../views/board/BoardDirectQuestion.vue')},
            {path: 'view/:id',name: 'board-directquestion-view', component: () => import('../views/board/BoardDirectQuestionForm.vue')},
        ]
    },
    {path: '/board/notice',name: 'board-notice', component: () => import('../views/board/Board.vue'),
        children: [
            {path: '',name: 'board-notice', component: () => import('../views/board/BoardNotice.vue')},
            {path: 'form',name: 'board-notice-form', component: () => import('../views/board/BoardNoticeForm.vue')},
            {path: 'view/:id',name: 'board-notice-view', component: () => import('../views/board/BoardNoticeForm.vue')},
        ]
    },
    {path: '/board/event',name: 'board-banner-event', component: () => import('../views/board/BannerEvent.vue')},
    {path: '/board/eventForm',name: 'board-banner-eventForm', component: () => import('../views/board/BannerEventForm.vue')},
    {path: '/board/eventView/:id',name: 'board-banner-eventView', component: () => import('../views/board/BannerEventForm.vue')},

    {path: '/board/magazineBoard', name: 'board-magazineBoard', component: () => import('../views/board/MagazineBoard.vue')},
    {path: '/board/magazineBoardForm', name: 'board-magazineBoardForm', component: () => import('../views/board/MagazineBoardForm.vue')}, 
    {path: '/board/magazineBoardForm/:id', name: 'board-magazineBoardView', component: () => import('../views/board/MagazineBoardForm.vue')}, 

    {path: '/board/goodsInquiry', name: 'board-goodsInquiry', component: () => import('../views/board/GoodsInquiry.vue')},
    {path: '/board/goodsInquiryView/:id', name: 'board-goodsInquiry-view', component: () => import('../views/board/GoodsInquiryForm.vue')},

    {path: '/board/review', name: 'board-review', component: () => import('../views/board/Review.vue')},
]