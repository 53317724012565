export default  {

	isNumber(str) {
		const regx = /^[0-9]+$/; 
		return regx.test(str);
	},

	/**
	 * Null 체크
	 * @param {*} value 
	 */
	isEmpty : value => { 
		if( value == "" || value == null || value == undefined || ( value != null && typeof value == "object" && !Object.keys(value).length ) ){
			return true 
		}else{
			return false 
		}
	},
	cloneObject(obj) {
		var clone = {};
		for (var key in obj) {
		if (typeof obj[key] == "object" && obj[key] != null) {
			clone[key] = this.cloneObject(obj[key]);
		} else {
			clone[key] = obj[key];
		}
		}
	
		return clone;
	},      
	/**
	 * @params
	 *  - str : 원 문자열
	 *  - padLen : 최대 채우고자 하는 길이
	 *  - padStr : 채우고자하는 문자(char)
	 */
	lpad(str , padLen , padStr){
		if (padStr.length > padLen) return str;

		str += ""; 
		padStr += ""; 
		while (str.length < padLen)
			str = padStr + str;
		str = str.length >= padLen ? str.substring(0, padLen) : str;
		return str;
	},
	/**
	 * 바이트 단위 표시
	 * @param {바이트} byte
	 * @param {소수점 자리수} d
	 */
	formatBytes : (byte, d) => {
		if(0 == byte) return "0 Bytes";
		let c = 1024;
		let digit = d || 2;
		let e = ["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"];
		let f = Math.floor(Math.log(byte) / Math.log(c));
		return parseFloat((byte / Math.pow(c,f)).toFixed(digit))+" "+e[f]
	},
	/**
	 * 숫자 콤마
	 * @param {숫자} num 
	 * @returns 
	 */
	addComma(num) {
		if(num == undefined) return "";
		if( !this.isNumber(num)) return num;
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	},

	removeComma(str){
		return str.replace(/[^0-9\.]/g, '');
	},

	/**
	 * 날자 변환
	 * yyyy-MM-dd, yyyy-MM-dd HH:mm:ss, yyyy-MM-dd a/p hh:mm:ss, yyyy-MM-dd E
	 * @param {날짜 Date} format 
	 * @returns 
	 */
	getDate(format, date) {

		if(date == null) return "";

		let weekName = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
		let d = new Date(date);
		let h;
		String.prototype.string = function (len) { var s = '', i = 0; while (i++ < len) { s += this; } return s; };
		String.prototype.zf = function (len) { return "0".string(len - this.length) + this; };
		Number.prototype.zf = function (len) { return this.toString().zf(len); };
		 
		return format.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|a\/p)/gi, function($1) {
			switch ($1) {
				case "yyyy": return d.getFullYear();
				case "yy": return (d.getFullYear() % 1000).zf(2);
				case "MM": return (d.getMonth() + 1).zf(2);
				case "dd": return d.getDate().zf(2);
				case "E": return weekName[d.getDay()];
				case "HH": return d.getHours().zf(2);
				// case "hh": return ((h = d.getHours() % 12) ? h : 12).zf(2);
				case "hh": return ((h = d.getHours() % 12) ? h : 12);
				case "mm": return d.getMinutes().zf(2);
				case "ss": return d.getSeconds().zf(2);
				case "a/p": return d.getHours() < 12 ? "오전" : "오후";
				default: return $1;
			}
		});
	},

	addDate(date, interval, units) {
		if(!(date instanceof Date)) return undefined;

		let ret = new Date(date); //don't change original date
		let checkRollover = function() { if(ret.getDate() != date.getDate()) ret.setDate(0);};
		switch(String(interval).toLowerCase()) {
		  case 'year'   :  ret.setFullYear(ret.getFullYear() + units); checkRollover();  break;
		  case 'quarter':  ret.setMonth(ret.getMonth() + 3*units); checkRollover();  break;
		  case 'month'  :  ret.setMonth(ret.getMonth() + units); checkRollover();  break;
		  case 'week'   :  ret.setDate(ret.getDate() + 7*units);  break;
		  case 'day'    :  ret.setDate(ret.getDate() + units);  break;
		  case 'hour'   :  ret.setTime(ret.getTime() + units*3600000);  break;
		  case 'minute' :  ret.setTime(ret.getTime() + units*60000);  break;
		  case 'second' :  ret.setTime(ret.getTime() + units*1000);  break;
		  default       :  ret = undefined;  break;
		}
		return ret;
	  },

	  formatBusinessNumber(str){
		if(str == undefined) return "";
		if(typeof str === 'object' ){
			str.value = str.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
			return;
		}else{
			return str.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
		}
	  },
}


