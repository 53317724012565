import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import toastStore from "./toast-grid.js";

export default createStore({
	state: {
		userInfo : {},
		menuList: [],
		menuId : 0,
		navInfo : {},
		onToggleCollapse : {collapsed : false},

		loginExprTime : 60 * 30 ,	// 로그인 만료시간 30분
		remainLoginTime : 0	// 로그인 남은 시간
		
	},
	mutations: {
	    setUserInfo: (state, userInfo) => {
			state.userInfo = userInfo
		}, 
		setRefreshToken: (state, token) => {
			state.userInfo.refreshToken = token
		}, 
		logOut: (state) => {
			state.userInfo = {}
		}, 
		setMenuList: (state, menuList) => {
			state.menuList = menuList;
		}, 
		setNavInfo: (state, navInfo) => {
			state.navInfo = navInfo;
		},
		setToggleCollapse : (state, collapsed ) => {
			state.onToggleCollapse.collapsed = collapsed;
		},
		setMenuId: (state, menuId) => {
			state.menuId = menuId;
		},
		setRemainLoginTime: (state, remainLoginTime) => {
			state.remainLoginTime = remainLoginTime;
		},
	},
	actions: {
	},
	modules: {
		toast : toastStore
	},

	  // 새로고침 데이터 유지
	  plugins: [
		createPersistedState({
		   storage: window.localStorage,
		   key : "vue-adm",
		   paths: ["menuList", "userInfo","navInfo","onToggleCollapse", "remainLoginTime"],
		 })
	  ]
})
