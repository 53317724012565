export default [
    {path: '/site/bannerTop', name: 'site-bannerTop', component:  () => import('../views/site/BannerTop.vue')},
    {path: '/site/bannerMain', name: 'banner', component:  () => import('../views/site/BannerList.vue')},
    {path: '/site/bannerMainForm',name: 'banner-form', component: () => import('../views/site/BannerForm.vue')},
    {path: '/site/bannerMainView/:id',name: 'banner-view', component: () => import('../views/site/BannerForm.vue')},
    {path: '/site/bannerReview',name: 'banner-reView', component: () => import('../views/site/BannerReviewForm.vue')},
    {path: '/site/bannerManage',name: 'banner-manage', component: () => import('../views/site/BannerManagement.vue')},
    {path: '/site/bannerManage/todayHealthy',name: 'todayHealthy-manage', component: () => import('../views/site/TodayHealthy.vue')},
    {path: '/site/bannerManage/addBanner',name: 'add-banner', component: () => import('../views/site/addBanner.vue')},
    {path: '/site/bannerManage/updateBanner/:mainTop/:id',name: 'update-banner', component: () => import('../views/site/updateBanner.vue')},
    {path: '/site/exposureReview',name: 'exposure-reView', component: () => import('../views/site/ExposureReview.vue')},
    {path: '/site/exposureReviewView',name: 'exposure-reView-view', component: () => import('../views/site/ExposureReviewForm.vue')},

    {path: '/site/partnersImageForm',name: 'partners-form', component: () => import('../views/site/PartnersImageForm.vue')},
    {path: '/site/categoryImage',name: 'category-image', component: () => import('../views/site/CategoryImage.vue')},
    {path: '/site/categoryImageForm',name: 'category-image-form', component: () => import('../views/site/CategoryImageForm.vue')},
    {path: '/site/searchValueManagement',name: 'site-searchValueManagement', component: () => import('../views/site/SearchValueManagement.vue')},

    {path: '/site/magazine',name: 'site-magazine', component: () => import('../views/site/Magazine.vue')},

    {path: '/site/magazineVideo',name: 'site-magazineVideo', component: () => import('../views/site/MagazineVideo.vue')},
    {path: '/site/counselingPurpose',name: 'counseling-reView', component: () => import('../views/site/CounselingPurpose.vue')},

    {path: '/site/counselWaitingManage', name: 'site-counselWaitingManage', component: () => import('../views/site/CounselWaitingManage.vue')},
    {path: '/site/counselWaitingManage/form', name: 'site-counselWaitingManage-form', component: () => import('../views/site/CounselWaitingManageForm.vue')},
    {path: '/site/counselWaitingManage/:id', name: 'site-counselWaitingManage-view', component: () => import('../views/site/CounselWaitingManageForm.vue'), props: true},

]