export default {

  state : {
    options : {
      scrollX: false,
      scrollY: false,
      header : {height : 32},
      minRowHeight : 28,
      rowHeight: 28,
      bodyHeight  : 560,
      columnOptions: {resizable: true},
      // useClientSort: false,
      // pageOptions: {perPage: 15}
    },
    theme : {
        cell: {
          normal: {
            background: '#fff',
            // border: '#e0e0e0',
            showVerticalBorder: true
          },
          header: {
            // background: '#f7f7f7',
            // border: '#e0e0e0',
            showVerticalBorder: true
          },
          editable: {
             background: '#fbfbfb',
          },
        },
        row: {
          hover:{
            background : '#ecf5ff;'
          }
        }
      },
    }
}